$dsl_width: 300px;

.diva-skip-links {
    position: fixed;
    top: -100%;
    right: 0;
    left: 0;
    z-index: 9999;
    width: $dsl_width;
    margin: 0 auto;

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__link {
        position: fixed;
        display: block;
        top: -100%;
        width: $dsl_width;
        padding: 5px;
        background-color: #cef2ff;
        text-align: center;

        &:focus {
            top: 7px;
        }
    }
}